import React from "react";
import Layout from "../components/layout";
import PrivacyPolicy from "../components/legal/PrivacyPolicy";
import Seo from "../components/seo";

const PrivacyPage = () => (
  <Layout>
    <Seo title="Privacy Policy" />
    <PrivacyPolicy />
  </Layout>
);

export default PrivacyPage;
